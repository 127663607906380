import React from "react";

import Layout from "components/Layout";
import SEO from "components/SEO";
import Posts from "components/Posts";
import VirtualReality from "components/VirtualReality";

const VirtualRealityPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Work" />
      <VirtualReality />
    </Layout>
  );
};

export default VirtualRealityPage;
